import { Compact, CompactRow, FieldLabel, FieldSelectMulti } from '@newageerp/v3.bundles.form-bundle'
import { useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NaePathsMap } from '../../../_generated/_custom/config/NaePaths';

type Props = {
    skuPlans: string[],
    setSkuPlans: (v: string[]) => void,
}

export default function PlanSkuFilter(props: Props) {
    const { t } = useTranslation();
    const { skuPlans, setSkuPlans } = props;

    const [doReq, reqData] = useURequest<string>(NaePathsMap.post['reportsFilterPlanSku']);

    useEffect(() => {
        doReq({})
    }, []);

    return (
        <Compact>
            <CompactRow
                label={<FieldLabel>{t('Plan sku')}</FieldLabel>}
                control={
                    <FieldSelectMulti
                        value={skuPlans}
                        onChange={setSkuPlans}
                        options={[
                            ...reqData.data.data.map(s => {
                                return (
                                    {
                                        label: s,
                                        value: s,
                                    }
                                )
                            }),
                        ]}
                        asString={true}
                        allowSelectAll={true}
                    />
                }
            />
        </Compact>
    )
}
