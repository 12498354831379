import { useURequestRaw } from '@newageerp/v3.bundles.hooks-bundle';
import React, { Fragment, useEffect } from 'react'
import { NaePathsMap } from '../../../_generated/_custom/config/NaePaths';
import { groupMap, numberFormat } from '@newageerp/v3.bundles.utils-bundle';
import { Table, Th, Td } from '@newageerp/v3.bundles.layout-bundle';
import { DashboardGrossV2borderLeft, DashboardGrossV2borderRight } from '../DashboardGrossV2';
import classNames from 'classnames';

type Props = {
  chartDates: {
    dateFrom: string,
    dateTo: string,
  },
  dateType: string,
}

export default function CancelationsReport(props: Props) {
  const { chartDates, dateType } = props;

  const [doReq, reqData] = useURequestRaw<any>(NaePathsMap.post.pluginsDashboardSubsStopDateReport);

  const loadData = () => {
    doReq({
      dateFrom: chartDates.dateFrom,
      dateTo: chartDates.dateTo,
      dateKey: dateType,
    })
  }

  useEffect(() => {
    loadData();
  }, [props]);

  if (!reqData.data) {
    return <Fragment />
  }

  const groupedDates = groupMap(reqData.data.data, (el) => el.stop_date)
  const dates = Object.keys(groupedDates)

  const groupedReasons = groupMap(reqData.data.data, (el) => el.stop_reason)
  const reasons = Object.keys(groupedReasons)

  return (
    <Fragment>
      {!!reqData.data.data &&
        <Table
          thead={
            <thead>
              <tr>
                <Th className={DashboardGrossV2borderRight}>Date</Th>
                {reasons.map(p => {
                  return <Fragment key={`p-${p}`}>
                    <Th textAlignment={'text-right'}>{p}</Th>

                  </Fragment>
                })}
                <Th textAlignment={'text-right'} className={DashboardGrossV2borderLeft}>Total</Th>
              </tr>
            </thead>
          }
          tbody={<tbody>
            {dates.map((date: string, iId: number) => {
              const tItems = reqData.data.data.filter((el: any) => el.stop_date === date);
              const tT = tItems.map((i: any) => i.c).reduce((a: number, b: number) => a + b, 0);

              return (
                <tr key={`sl-${date}`}>
                  <Td className={classNames(DashboardGrossV2borderRight, 'whitespace-nowrap')}>{date}</Td>
                  {reasons.map(p => {
                    const item = reqData.data.data.find((el: any) => el.stop_date === date && el.stop_reason === p);

                    const q = item ? item.c : 0;

                    return <Fragment key={`p-${date}-${p}`}>
                      <Td textAlignment={'text-right'} className='text-slate-700'>{q.toFixed(0)}</Td>
                    </Fragment>
                  })}
                  <Td textAlignment={'text-right'} className={classNames(DashboardGrossV2borderLeft, 'font-medium whitespace-nowrap')}>{numberFormat({ userInput: tT, decimals: 0, })}</Td>
                </tr>
              )
            })}
            <tr className='total-row font-bold'>
              <Td>Total</Td>
              {reasons.map(p => {
                const items = reqData.data.data.filter((el: any) => el.stop_reason === p);

                const q = items.map((i: any) => i.c).reduce((a: number, b: number) => a + b, 0);

                return <Fragment key={`p-total-${p}`}>
                  <Td textAlignment={'text-right'} className='text-slate-700 whitespace-nowrap'>{q.toFixed(0)}</Td>
                </Fragment>
              })}
              <Td textAlignment={'text-right'} className={classNames(DashboardGrossV2borderLeft, 'font-medium whitespace-nowrap')}>
                {numberFormat({ userInput: reqData.data.data.map((i: any) => i.c).reduce((a: number, b: number) => a + b, 0), decimals: 0, })}
              </Td>
            </tr>

          </tbody>}
        />
      }
    </Fragment>
  )
}
