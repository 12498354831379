import { Compact, CompactRow, FieldDateRange, FieldLabel } from '@newageerp/v3.bundles.form-bundle';
import { useURequestRaw } from '@newageerp/v3.bundles.hooks-bundle';
import { LogoLoader, MainToolbarTitle, Table, Th, Td } from '@newageerp/v3.bundles.layout-bundle';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { NaePathsMap } from '../../../_generated/_custom/config/NaePaths';
import classNames from 'classnames';

export default function DashboardRecurring() {
    const { t } = useTranslation();

    const [reqChartsData, reqDataChartsParams] = useURequestRaw(NaePathsMap.post.RecurringControllerCalculate);

    const [type, setType] = useState('counts');
    const [stats, setStats] = useState<any>();

    const defDates = {
        dateFrom: moment().add(-10, 'day').format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD")
    };
    const [chartDates, setChartDates] = useState(defDates);
    const clearState = () => {
        setChartDates(defDates)
    }

    const loadCharData = () => {
        reqChartsData({
            dateFrom: chartDates.dateFrom,
            dateTo: chartDates.dateTo,
        }).then((res: any) => {
            setStats(res.data);
        })
    }
    useEffect(() => {
        loadCharData();
    }, [chartDates]);

    const groupByTabs = [
        {
            title: 'PCS',
            key: 'counts'
        },
        {
            title: 'Totals',
            key: 'totals'
        }
    ]

    const borderLeft = 'border-l border-blue-400';

    return (
        <Fragment>
            <MainToolbarTitle title={"Recurring dashboard"} />
            <div className='space-y-4'>
                <WhiteCard isCompact={true}>
                    <div className='flex gap-4 items-end'>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Date')}</FieldLabel>}
                                control={
                                    <FieldDateRange dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />
                                }
                            />
                        </Compact>
                    </div>
                </WhiteCard>
                {reqDataChartsParams.loading &&
                    <div className='flex justify-center'>
                        <LogoLoader size={60} />
                    </div>
                }

                {!!stats && !reqDataChartsParams.loading && !!stats.output && <Fragment>
                    <div className="w-full">
                        <div className={classNames('border-b', 'border-slate-200')}>
                            {groupByTabs.map((t) => {
                                const active = t.key === type;
                                return (
                                    <button
                                        className={
                                            classNames(
                                                'text-sm',
                                                { 'font-semibold': active },
                                                'px-[16px]',
                                                'py-[8px]',
                                                { 'text-sky-500': active },
                                                { 'text-slate-500': !active },
                                            )
                                        }
                                        onClick={() => setType(t.key)}
                                        style={active ? { boxShadow: 'inset 0px -2px 0px 0px #0EA5E9' } : undefined}
                                    >
                                        {t.title}
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                    <Table
                        id='gross-pl-export'
                        thead={<thead>
                            <tr>
                                <Th>Date</Th>
                                {stats.statuses.map((s: string) => {
                                    return <Fragment key={`s-${s}`}>
                                        <Th className={borderLeft} textAlignment='text-right'>{s}</Th>
                                        {s.indexOf('All') === -1 &&
                                            <Th textAlignment='text-right'>Rate</Th>
                                        }
                                    </Fragment>
                                })}
                            </tr>
                        </thead>}
                        tbody={<tbody>
                            {stats.dates.map((d: string) => {
                                return <tr key={`d-${d}`}>
                                    <Td>{d}</Td>
                                    {stats.statuses.map((s: string) => {
                                        const allS = s.replace('error', 'All').replace('paid', 'All');
                                        return <Fragment key={`s-${s}-${d}`}>
                                            <Td className={borderLeft} textAlignment='text-right'>{stats['output'][type][d][s].toFixed(0)}</Td>
                                            {s.indexOf('All') === -1 &&
                                                <Td textAlignment='text-right'>{stats['output'][type][d][allS] > 0 ? (stats['output'][type][d][s] / stats['output'][type][d][allS] * 100).toFixed(2) : "X"}%</Td>
                                            }
                                        </Fragment>
                                    })}
                                </tr>
                            })}
                            <tr className='total-row font-medium'>
                                <Td>Total</Td>
                                {stats.statuses.map((s: string) => {
                                    const allS = s.replace('error', 'All').replace('paid', 'All');

                                    return <Fragment key={`s-${s}-tot`}>
                                        <Td className={borderLeft} textAlignment='text-right'>{stats['output'][`all_${type}`][s].toFixed(0)}</Td>
                                        {s.indexOf('All') === -1 &&
                                            <Td textAlignment='text-right'>{stats['output'][`all_${type}`][allS] > 0 ? (stats['output'][`all_${type}`][s] / stats['output'][`all_${type}`][allS] * 100).toFixed(2) : "X"}%</Td>
                                        }
                                    </Fragment>
                                })}
                            </tr>
                        </tbody>}
                    />
                </Fragment>}
            </div>
        </Fragment>
    )
}
