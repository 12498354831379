import { useUList } from '@newageerp/v3.bundles.hooks-bundle'
import { LogoLoader } from '@newageerp/v3.bundles.layout-bundle'
import { getKeysFromObject } from '@newageerp/v3.bundles.utils-bundle'
import { DateCardWidget, NumberCardWidget, WhiteCard } from '@newageerp/v3.bundles.widgets-bundle'
import React, { Fragment, useEffect } from 'react'
import { useNaeRecord } from '@newageerp/v3.app.mvc.record-provider';
import { TextCardTitle } from '@newageerp/v3.bundles.typography-bundle'
import moment from 'moment'
import ReportItemSubscriptionsToolbar from './ReportItemSubscriptionsToolbar'

type Props = {
  reportItem: {
    orderId: string,
  }
}

export type ReportItemWidgetSubscription = {
  id: number,
  startDate: string,
  finishDate: string,
  active: boolean,
  lifetime: boolean,
  subscriptionType: string,
  lastPaymentDate: string,
  nextSubscriptionPayment: string,
  period: string,
  total: number,
}

const defSubscription: ReportItemWidgetSubscription = {
  id: 0,
  startDate: '',
  finishDate: '',
  active: false,
  lifetime: false,
  subscriptionType: '',
  lastPaymentDate: '',
  nextSubscriptionPayment: '',
  period: '',
  total: 0,
}

export default function ReportItemSubscriptions(props: Props) {
  const { element } = useNaeRecord();
  const [reqData, reqDataParams] = useUList<ReportItemWidgetSubscription>('subscription', getKeysFromObject(defSubscription));

  const loadData = () => {
    reqData(
      [{
        "and": [
          ["i.orderId", '=', props.reportItem.orderId, true]
        ]
      }],
      1,
      9999,
      [{ key: 'i.startDate', value: 'ASC' }]
    )
  }

  useEffect(() => {
    loadData();
  }, [element]);

  return (
    <Fragment>
      {reqDataParams.loading && <div className='flex justify-center'><LogoLoader size={20} /></div>}
      <div className='space-y-4'>
        {reqDataParams.data.data.map(el => (
          <WhiteCard key={`sub-${el.id}`} className='space-y-2'>
            <div className='flex items-center gap-4'>
              <TextCardTitle>Subscription ({el.subscriptionType})</TextCardTitle>

              <ReportItemSubscriptionsToolbar el={el} reload={loadData} />
            </div>
            <div className='grid grid-cols-4 gap-4'>
              {!!el.total &&
                <NumberCardWidget
                  title="Plan price"
                  children={el.total}
                  asFloat={true}
                  color='sky'
                  isCompact={true}
                />
              }
              {!!el.startDate &&
                <DateCardWidget
                  title='Start date'
                  children={moment(el.startDate).format('YYYY-MM-DD')}
                  isCompact={true}
                  color='teal'
                />
              }
              {!!el.finishDate &&
                <DateCardWidget
                  title='Finish date'
                  children={moment(el.finishDate).format('YYYY-MM-DD')}
                  isCompact={true}
                  color='red'
                />
              }
              {!!el.lastPaymentDate &&
                <DateCardWidget
                  title='Last payment'
                  children={el.lastPaymentDate}
                  isCompact={true}
                  color='sky'
                />
              }
              {!!el.nextSubscriptionPayment &&
                <DateCardWidget
                  title='Next payment'
                  children={el.nextSubscriptionPayment}
                  isCompact={true}
                  color={'teal'}
                />
              }
            </div>
          </WhiteCard>
        ))}
      </div>
    </Fragment>
  )
}
