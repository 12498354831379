import { TabContainer } from '@newageerp/v3.bundles.layout-bundle';
import { axiosInstance } from '@newageerp/v3.bundles.utils-bundle'
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { SFSForm } from '@newageerp/v3.templates.json-form';
import React, { Fragment, useEffect, useState } from 'react'
import validator from '@rjsf/validator-ajv8'
import { Table, Th, Td } from '@newageerp/v3.bundles.layout-bundle';
import classNames from 'classnames';
import { useQuery } from '@newageerp/v3.bundles.hooks-bundle';
import { useParams } from '@newageerp/v3.templates.templates-core';

export default function AbTestManagementForm() {
    const routeParams = useParams<{ project: string }>();

    const [config, setConfig] = useState<any>();
    const [enumsData, setEnumsData] = useState<any>()
    const [data, setData] = useState<any>()

    const updateData = async () => {
        const _data = await getItem('funnels')
        if (_data) {
            setData(_data)
        }
    }

    useEffect(() => {
        updateData().catch(console.error)
    }, [])

    const loadConfig = async () => {
        if (routeParams.project) {
            const res = await axiosInstance.post('/app/nae-core/sf-config/getUserConfig', { config: 'abtest' })
            if (!!res.data && !!res.data.success) {
                const data = res.data.data.find((el: any) => el.project === routeParams.project);

                if (data) {
                    const resEnums = await fetch(`${data.enumsUrl}/api/enums`, {
                        method: 'POST'
                    })
                    const resEnumsJson = await resEnums.json();

                    setEnumsData(resEnumsJson.data);
                    setConfig(data);
                }
            }
        }
    }
    useEffect(() => {
        setEnumsData(undefined);
        setConfig(undefined);
        loadConfig().catch(console.error);
    }, [routeParams.project])

    const clearAbData = async () => {
        for (const projectUrl of config.projectUrls) {
            const url = `${projectUrl}/api/ab-data/clear`
            const _res = await fetch(url, {
                body: JSON.stringify({}),
                method: 'POST',
                headers: {
                    // 'Content-Type': 'application/json',
                },
                cache: 'no-store',
            })
        }
    }

    const getItem = async (slug: string) => {
        const res = await fetch(`${config.dataUrl}/funnel/get`, {
            body: JSON.stringify({
                slug,
                project: config.project,
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        const resJson = await res.json()
        return resJson?.data?.templates
    }
    const updateItem = async (slug: string, formData: any) => {
        await fetch(`${config.dataUrl}/funnel/updateTemplates`, {
            body: JSON.stringify({
                slug,
                templates: formData,
                project: config.project,
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        await clearAbData()
    }

    if (!config) {
        return <Fragment />
    }

    return (
        <div>
            <WhiteCard>
                <div className='flex gap-4'>
                    <TabContainer
                        items={[
                            {
                                tab: {
                                    children: 'Funnels',
                                },
                                content: (
                                    <FunnelsForm
                                        getItem={getItem}
                                        updateItem={updateItem}
                                        enumsData={enumsData}
                                        updateData={updateData}
                                        funnelsData={data}
                                    />
                                ),
                            },
                            {
                                tab: {
                                    children: 'Traffic split',
                                },
                                content: (
                                    <TrafficSplitForm
                                        getItem={getItem}
                                        updateItem={updateItem}
                                        enumsData={enumsData}
                                        updateData={updateData}
                                        funnelsData={data}
                                    />
                                ),
                            },
                        ]}
                    />

                    <div className='space-y-2 w-[400px] text-sm'>
                        {!!data && <div className={'space-y-4 mt-6'}>
                            <div className={'divide-y divide-slate-300'}>
                                {data.map((e: any, idx: number) => <div key={`idx-${idx}`} className='space-y-1 py-1'>
                                    <div className='flex gap-4'><p>{idx + 1}) /{e.landing}/{e.checkout}</p>
                                        {config.projectUrls.length > 0 && <span className='flex gap-2'>
                                            {config.projectUrls.map((l: string) => <a key={`${l}-${idx}`} href={`${linkForVariant(l, e)}`} className='text-blue-500 underline hover:no-underline' target='_blank'>
                                                <i className='fa-regular fa-link' title={l} />
                                            </a>)}
                                        </span>}
                                    </div>
                                    <p>{e.internalNote}</p>
                                </div>)}
                            </div>
                        </div>}
                    </div>
                </div>
            </WhiteCard>
        </div>
    )
}

const linkForVariant = (url: string, e: any) => {
    return `${url}/${e.landing}/${e.checkout}`;
}

type TabProps = {
    getItem: (slug: string) => any;
    updateItem: (slug: string, formData: any) => void;
    enumsData: any,
    updateData: () => void,
    funnelsData: any
};

const FunnelsForm = (props: TabProps) => {
    const [data, setData] = useState<any>()
    const [isLoaded, setIsLoaded] = useState(false)

    const slug = 'funnels'

    let schema = {
        type: 'array',
        items: {
            type: 'object',
            properties: {
                landing: { type: 'string', title: 'Landing' },
                checkout: { type: 'string', title: 'Checkout' },
                // variant: { type: 'string', title: 'Variant' },
                // currency: { type: 'string', title: 'Currency', enum: ['usd', 'eur', 'gbp'], default: 'usd' },

                internalNote: { type: 'string', title: 'Internal note' },

                homePageTemplate: { type: 'string', title: 'Home page', enum: props.enumsData.homePageTemplate, default: 'Default' },
                quizPageTemplate: { type: 'string', title: 'Quiz page', enum: props.enumsData.quizPageTemplate, default: 'Default' },
                resultPageTemplate: { type: 'string', title: 'Results page', enum: props.enumsData.resultPageTemplate, default: 'Default' },
                emailPageTemplate: { type: 'string', title: 'Email page', enum: props.enumsData.emailPageTemplate, default: 'Default' },
                checkoutPageTemplate: { type: 'string', title: 'Checkout page', enum: props.enumsData.checkoutPageTemplate, default: 'Default' },
                upsellPageTemplate: { type: 'string', title: 'Upsell page', enum: props.enumsData.upsellPageTemplate ? props.enumsData.upsellPageTemplate : ['Default'], default: 'Default' },

            },
        },
    }

    useEffect(() => {
        const asyncF = async () => {
            const _data = await props.getItem(slug)
            if (_data) {
                setData(_data)
                props.updateData();
            }
            setIsLoaded(true)
        }
        asyncF().catch(console.error)
    }, [])

    return (
        <div className={'w-[600px]'}>
            {isLoaded && (
                <SFSForm
                    schema={schema}
                    validator={validator}
                    onSubmit={(e) => {
                        props.updateItem(slug, e.formData)
                        setData(e.formData)
                    }}
                    formData={data}
                />
            )}



        </div>
    )
}


const TrafficSplitForm = (props: TabProps) => {
    const [data, setData] = useState<any>()
    const [isLoaded, setIsLoaded] = useState(false)

    const slug = 'traffic'

    let schema = {
        type: 'array',
        items: {
            type: 'object',
            required: [
                "input"
            ],
            properties: {
                input: { type: 'string', title: 'Input' },
                split: {
                    type: 'array',
                    title: 'Split',
                    items: {
                        type: 'object',
                        required: [
                            "url",
                            "split"
                        ],
                        properties: {
                            url: { type: 'string', title: 'Url' },
                            split: { type: 'number', title: 'Percent' },
                        },
                    },
                },
            },
        },
    }

    useEffect(() => {
        const asyncF = async () => {
            const _data = await props.getItem(slug)
            if (_data) {
                setData(_data)
            }
            setIsLoaded(true)
        }
        asyncF().catch(console.error)
    }, [])

    return (
        <div className={'w-[600px]'}>
            {isLoaded && !!data && (
                <div className='space-y-6'>
                    <SFSForm
                        schema={schema}
                        validator={validator}
                        onSubmit={(e) => {
                            props.updateItem(slug, e.formData)
                            setData(e.formData)
                        }}
                        formData={data}
                    />

                    <Table
                        thead={
                            <thead>
                                <tr>
                                    <Th>Input</Th>
                                    <Th></Th>
                                    <Th>Split</Th>
                                    <Th>URL</Th>
                                </tr>
                            </thead>
                        }
                        tbody={
                            <tbody>
                                {data.map((el: any) => {
                                    const funnels = props.funnelsData.map((e: any) => `${e.landing}/${e.checkout}`)
                                    const isOk = funnels.indexOf(el.input) === -1;
                                    const percents = el.split.map((e: any) => e.split).reduce((a: number, b: number) => a + b, 0);

                                    return (
                                        <Fragment key={`split-${el.input}`}>
                                            <tr>
                                                <Td rowSpan={el.split.length + 1}>
                                                    {el.input}

                                                    <i className={
                                                        classNames(
                                                            'fa fa-solid',
                                                            { 'fa-check text-green-500': isOk },
                                                            { 'fa-ban text-red-500': !isOk }
                                                        )}
                                                    />
                                                </Td>
                                                <Td></Td>
                                                <Td>{percents}% <i className={
                                                    classNames(
                                                        'fa fa-solid',
                                                        { 'fa-check text-green-500': percents === 100 },
                                                        { 'fa-ban text-red-500': percents !== 100 }
                                                    )}
                                                /></Td>
                                                <Td></Td>
                                            </tr>
                                            {el.split.map((split: any, idx: number) => {
                                                const isOk = funnels.indexOf(split.url) >= 0

                                                return (
                                                    <tr>
                                                        <Td>{idx + 1}</Td>
                                                        <Td>{split.split}%</Td>
                                                        <Td>
                                                            {split.url}
                                                            <i className={
                                                                classNames(
                                                                    'fa fa-solid',
                                                                    { 'fa-check text-green-500': isOk },
                                                                    { 'fa-ban text-red-500': !isOk }
                                                                )}
                                                            />
                                                        </Td>
                                                    </tr>
                                                )
                                            })}
                                        </Fragment>
                                    )
                                })}

                            </tbody>
                        }
                    />

                </div>
            )}
        </div>
    )
}