import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { Compact, CompactRow, FieldDateRange, FieldLabel } from '@newageerp/v3.bundles.form-bundle';
import { MainToolbarTitle, Table, Th, Td, TabContainer } from '@newageerp/v3.bundles.layout-bundle'
import { groupMap } from '@newageerp/v3.bundles.utils-bundle';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useUIBuilder } from "@newageerp/v3.app.mvc.ui-builder";
import { useLocalStorage, useUList, useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../_generated/_custom/config/NaePaths';
import classNames from 'classnames';
import CountryFilter, { CountryFilterCountry } from './Components/CountryFilter';
import FunnelFilter from './Components/FunnelFilter';

type ConfigItem = {
    title: string,
    sql: string,
    columns: {
        field: string,
        title: string,
        type: string
    }[]
}

export default function DashboardRealtimeV2Custom() {
    const { settings } = useUIBuilder();
    const { t } = useTranslation();


    const [lastReload, setLastReload] = useState('');
    // const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));

    const [doReq, doReqParams] = useURequest<ConfigItem>(NaePathsMap.post.AnalyticsCustomList);

    useEffect(() => {
        doReq();
    }, []);

    const defDates = {
        dateFrom: moment().format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD")
    };

    const [countries, setCountries] = useLocalStorage('CRM_realtime_custom_country', []);
    const [countriesReq, countriesData] = useUList<CountryFilterCountry>("country", ['id', 'name']);
    const [funnels, setFunnels] = useLocalStorage('DashboardFunnelFilter', []);

    useEffect(() => {
        countriesReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
    }, []);

    const [chartDates, setChartDates] = useState(defDates);
    const clearState = () => {
        setChartDates(defDates)
    }


    return (
        <Fragment>
            <MainToolbarTitle title={lastReload} />

            <div className='space-y-4'>
                <WhiteCard isCompact={true}>
                    <div className='flex gap-4 items-end'>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Date')}</FieldLabel>}
                                control={
                                    <FieldDateRange dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />
                                }
                            />
                        </Compact>

                        <CountryFilter
                            countries={countries}
                            countriesData={countriesData.data.data}
                            setCountries={setCountries}
                        />

                        <FunnelFilter
                            funnels={funnels}
                            setFunnels={setFunnels}
                        />

                    </div>
                </WhiteCard>

                <WhiteCard>
                    <TabContainer
                        items={doReqParams.data.data.map(item => {
                            return {
                                content: <DashboardConfig chartDates={chartDates} item={item} countries={countries} funnels={funnels} />,
                                tab: {
                                    children: item.title,
                                }
                            }
                        })}
                    />
                </WhiteCard>

            </div>
        </Fragment>
    )
}

type ConfigProps = {
    item: ConfigItem,
    chartDates: {
        dateFrom: string,
        dateTo: string,
    },
    countries: string[]
    funnels:string[]
}

const DashboardConfig = (props: ConfigProps) => {
    const { chartDates, countries, funnels } = props;
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadData = async () => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        try {
            let countriesSql = '';
            if (countries.length > 0) {
                countriesSql = ` and an_event.country in (${countries.map(c => `'${c}'`)}) `;
            }
            let funnelsSql = '';
            if (funnels.length > 0) {
                funnelsSql = ` and an_event.slug in (${funnels.map(c => `'${c}'`)}) `;
            }
            const sql = props.item.sql.replaceAll('|DATEFROM|', chartDates.dateFrom).replaceAll('|DATETO|', chartDates.dateTo).replaceAll('|COUNTRIESSELECT|', countriesSql).replaceAll('|FUNNELSSELECT|', funnelsSql);

            const res = await fetch(
                'https://an.apidata.app/api/crmStatsSql',
                {
                    method: 'POST',
                    body: JSON.stringify({ sql }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            const resJson = await res.json();
            setData(resJson.data ? resJson.data : []);
        } catch (e) {
            setData([]);
        }

        setIsLoading(false);

        // setLastReload(moment().format('YYYY-MM-DD HH:mm'));
    }

    useEffect(() => {
        loadData();
    }, [chartDates, countries]);

    return (
        <Fragment>
            <div className='space-y-4'>
                <MainButton onClick={loadData} iconName='repeat' color='sky' disabled={isLoading} loading={isLoading}>
                    Refresh
                </MainButton>

                <Table
                    thead={
                        <thead>
                            <tr>
                                {props.item.columns.map(c => {
                                    return (
                                        <Th key={`c-${c.field}`}
                                            textAlignment={
                                                classNames(
                                                    {
                                                        'text-left': c.type === 'string',
                                                        'text-right': c.type === 'number' || c.type === 'percent'
                                                    }
                                                )
                                            }
                                        >{c.title}</Th>
                                    )
                                })}

                            </tr>
                        </thead>
                    }
                    tbody={
                        <tbody>
                            {data.map((row: any, rIdx) => {
                                return <tr key={`row-${rIdx}`}>
                                    {props.item.columns.map(c => {
                                        return (
                                            <Td
                                                key={`row-${rIdx}c-${c.field}`}
                                                textAlignment={
                                                    classNames(
                                                        {
                                                            'text-left': c.type === 'string',
                                                            'text-right': c.type === 'number' || c.type === 'percent'
                                                        }
                                                    )
                                                }
                                            >

                                                {c.type === 'string' && row[c.field]}
                                                {c.type === 'number' && row[c.field].toFixed(0)}
                                                {c.type === 'percent' && `${row[c.field].toFixed(2)}%`}

                                            </Td>
                                        )
                                    })}
                                </tr>
                            })}
                        </tbody>
                    }
                />
            </div>
        </Fragment>
    )
}