import { useUList, useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { LogoLoader, MainToolbarTitle, Table, Td } from '@newageerp/v3.bundles.layout-bundle';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react'
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { Compact, CompactRow, FieldDateRange, FieldLabel, FieldSelect, FieldSelectMulti } from '@newageerp/v3.bundles.form-bundle';
import { useTranslation } from 'react-i18next';
import { NaePathsMap } from '../../_generated/_custom/config/NaePaths';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { numberFormat } from '@newageerp/v3.bundles.utils-bundle';

export default function DashboardProductPayback() {
    const { t } = useTranslation();

    const [countryGroup, setCountryGroup] = useState(0);

    const [stats, setStats] = useState<any>({});
    const [statsExtra, setStatsExtra] = useState<any>({});

    const defDates = {
        dateFrom: '2022-12-01',
        dateTo: moment().add(1, 'day').format("YYYY-MM-DD")
    };

    const [lang, setLang] = useState('');
    const [countries, setCountries] = useState<string[]>([]);

    const [chartDates, setChartDates] = useState(defDates);
    const clearState = () => {
        setChartDates(defDates)
    }

    const [countriesReq, countriesData] = useUList("country", ['id', 'name']);
    const [countryGroupsReq, countryGroupsData] = useUList("country-group", ['id', 'name', 'countries', 'countriesExclude']);
    useEffect(() => {
        countriesReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
        countryGroupsReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
    }, []);

    useEffect(() => {
        if (countryGroup <= 0) {
            setCountries([]);
        } else {
            const _cGroup: any = countryGroupsData.data.data.find((el: any) => el.id === countryGroup);
            let _countries: string[] = [];
            if (_cGroup) {
                const countriesExclude = _cGroup.countriesExclude.filter((c: string) => !!c);
                const countriesInclude = _cGroup.countries.filter((c: string) => !!c);
                if (countriesExclude.length > 0) {
                    _countries = countriesData.data.data.filter((c: any) => countriesExclude.indexOf(c.name) === -1).map((c: any) => c.name);
                }
                if (countriesInclude.length > 0) {
                    _countries = [..._countries, ...countriesInclude];
                }
            }
            setCountries(_countries);
        }
    }, [countryGroup]);

    const [reqData, reqDataParams] = useURequest(NaePathsMap.post['ProductPaybackCalculate']);
    const loadData = () => {
        reqData({
            dateFrom: chartDates.dateFrom,
            dateTo: chartDates.dateTo,
            lang,
            countries,
        }).then((res: any) => {
            setStats(res.data.reports);
            setStatsExtra(res.data.extra);
        })
    }

    return (
        <Fragment>
            <MainToolbarTitle title={"Payback period report"} />

            <div className='space-y-4'>
                <WhiteCard isCompact={true}>
                    <div className='flex gap-x-4 items-end flex-wrap gap-y-1'>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Date')}</FieldLabel>}
                                control={
                                    <FieldDateRange dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />
                                }
                            />
                        </Compact>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Language')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={lang}
                                        onChange={setLang}
                                        options={[
                                            {
                                                label: "All",
                                                value: "",
                                            },
                                            {
                                                label: "En",
                                                value: "en",
                                            },
                                            {
                                                label: "Es",
                                                value: "es",
                                            }
                                        ]}
                                    />
                                }
                            />
                        </Compact>

                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Country')}</FieldLabel>}
                                control={
                                    <FieldSelectMulti
                                        value={countries}
                                        onChange={setCountries}
                                        asString={true}
                                        options={
                                            countriesData.data.data.map((i: any) => {
                                                return (
                                                    {
                                                        label: i.name,
                                                        value: i.name,
                                                    }
                                                )
                                            })
                                        }
                                    />
                                }
                                controlClassName='max-w-[500px]'
                            />
                        </Compact>

                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Country group')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={countryGroup}
                                        onChange={setCountryGroup}
                                        options={[
                                            {
                                                label: "All",
                                                value: 0,
                                            },
                                            ...countryGroupsData.data.data.map((i: any) => {
                                                return (
                                                    {
                                                        label: i.name,
                                                        value: i.id,
                                                    }
                                                )
                                            })
                                        ]}
                                    />
                                }

                            />
                        </Compact>


                    </div>
                    <div>
                        <MainButton color={"sky"} iconName='filter' disabled={reqDataParams.loading} onClick={loadData}>Filter</MainButton>
                    </div>
                </WhiteCard>

                {reqDataParams.loading && <LogoLoader size={60} />}

                {!!statsExtra && statsExtra.loaded &&
                    <WhiteCard>
                        <Table
                            thead={<thead>
                                <tr>
                                    <Td></Td>
                                </tr>
                            </thead>}
                            tbody={
                                <tbody>
                                    {statsExtra.dates.map((d: string) => {
                                        return (
                                            <Fragment key={`d-${d}`}>
                                                <tr className='total-row font-bold'>
                                                    <Td xSmall={true} ySmall={true}>{d}</Td>
                                                    {statsExtra.periods.map((p: number) => {
                                                        return (
                                                            <Td xSmall={true} ySmall={true} textAlignment={'text-right'}>{`${p + 1}`} period</Td>
                                                        )
                                                    })}
                                                </tr>
                                                <tr>
                                                    <Td xSmall={true} ySmall={true}>Customers Acquired During Period</Td>
                                                    <Td xSmall={true} ySmall={true} textAlignment='text-right'>
                                                        {numberFormat({
                                                            userInput: stats[d].customers_acquired,
                                                            decimals: 0,
                                                            decPoint: '.',
                                                            thousandsSep: ' '
                                                        })}
                                                    </Td>
                                                </tr>
                                                <tr>
                                                    <Td xSmall={true} ySmall={true}>Subscribers Acquired During Period</Td>
                                                    <Td xSmall={true} ySmall={true} textAlignment='text-right'>
                                                        {numberFormat({
                                                            userInput: stats[d].subscribers_acquired,
                                                            decimals: 0,
                                                            decPoint: '.',
                                                            thousandsSep: ' '
                                                        })}
                                                    </Td>
                                                </tr>
                                                {statsExtra.plans.map((p: string) => {
                                                    return (
                                                        <tr key={`d-${d}-subs-acq-${p}`}>
                                                            <Td xSmall={true} ySmall={true}>---{p} subs acquired</Td>
                                                            <Td xSmall={true} ySmall={true} textAlignment='text-right'>
                                                                {numberFormat({
                                                                    userInput: stats[d].subscribers_acquired_plan[p],
                                                                    decimals: 0,
                                                                    decPoint: '.',
                                                                    thousandsSep: ' '
                                                                })}
                                                            </Td>
                                                        </tr>
                                                    )
                                                })}

                                                <tr className='font-medium border-t border-sky-300'>
                                                    <Td xSmall={true} ySmall={true}>Subscribers, EOP</Td>
                                                    <DrawFromTo
                                                        data={
                                                            stats[d].periods.map((p: number) => stats[d].drops[p])
                                                        }
                                                        start={stats[d].subscribers_acquired}
                                                    />
                                                </tr>
                                                {statsExtra.plans.map((pl: string) => {
                                                    return (
                                                        <tr key={`d-${d}-subs-eop-${pl}`}>
                                                            <Td xSmall={true} ySmall={true}>---{pl} subs, EOP</Td>
                                                            <DrawFromTo
                                                                data={
                                                                    stats[d].periods.map((p: number) => stats[d].drops_plan[pl][p])
                                                                }
                                                                start={stats[d].subscribers_acquired_plan[pl]}
                                                            />
                                                        </tr>
                                                    )
                                                })}

                                                <tr className='font-medium'>
                                                    <Td xSmall={true} ySmall={true}>Cancellations (Subscribers opted out), EOP</Td>
                                                    <DrawFromTo
                                                        data={
                                                            stats[d].periods.map((p: number) => stats[d].drops[p])
                                                        }
                                                        start={0}
                                                        add={true}
                                                    />
                                                </tr>
                                                {statsExtra.plans.map((pl: string) => {
                                                    return (
                                                        <tr key={`d-${d}-subs-cancellations-${pl}`}>
                                                            <Td xSmall={true} ySmall={true}>---{pl} cancellations</Td>
                                                            <DrawFromTo
                                                                start={0}
                                                                data={
                                                                    stats[d].periods.map((p: number) => stats[d].drops_plan[pl][p])
                                                                }
                                                                add={true}

                                                            />
                                                        </tr>
                                                    )
                                                })}

                                                <tr className='font-medium'>
                                                    <Td xSmall={true} ySmall={true}>Churn (Subscribers opted out from initial), %</Td>
                                                    <DrawFromToChurn
                                                        data={
                                                            stats[d].periods.map((p: number) => stats[d].drops[p])
                                                        }
                                                        start={stats[d].subscribers_acquired}
                                                        precision={2}
                                                    />
                                                </tr>
                                                {statsExtra.plans.map((pl: string) => {
                                                    return (
                                                        <tr key={`d-${d}-subs-churn-${pl}`}>
                                                            <Td xSmall={true} ySmall={true}>---{pl} churn, %</Td>
                                                            <DrawFromToChurn
                                                                start={stats[d].subscribers_acquired_plan[pl]}
                                                                data={
                                                                    stats[d].periods.map((p: number) => stats[d].drops_plan[pl][p])
                                                                }
                                                            />
                                                        </tr>
                                                    )
                                                })}


                                            </Fragment>
                                        )
                                    })}

                                    <tr>
                                        <Td>&nbsp;</Td>
                                    </tr>
                                    <tr className='font-medium total-row'>
                                        <Td xSmall={true} ySmall={true}>Payment plans split, pricing and billing cycles</Td>
                                        {statsExtra.periods.map((p: number) => {
                                            return (
                                                <Td xSmall={true} ySmall={true} textAlignment={'text-right'}>{`${p + 1}`} period</Td>
                                            )
                                        })}
                                    </tr>
                                    <tr className='font-medium'>
                                        <Td xSmall={true} ySmall={true}>Subscribers, EOP</Td>
                                        <DrawFromTo
                                            data={
                                                statsExtra.periods.map((p: number) => stats['all'].drops[p])
                                            }
                                            start={stats['all'].subscribers_acquired}
                                        />
                                    </tr>
                                    {statsExtra.plans.map((pl: string) => {
                                        return (
                                            <tr key={`d-all-subs-split-${pl}`}>
                                                <Td xSmall={true} ySmall={true}>---{pl} plan part in all subs, %</Td>
                                                <DrawFromToPercent
                                                    data={
                                                        statsExtra.periods.map((p: number) => stats['all'].drops_plan[pl][p])
                                                    }
                                                    start={stats['all'].subscribers_acquired_plan[pl]}

                                                    dataAll={
                                                        statsExtra.periods.map((p: number) => stats['all'].drops[p])
                                                    }
                                                    startAll={stats['all'].subscribers_acquired}
                                                />
                                            </tr>
                                        )
                                    })}
                                    <tr className='font-medium total-row'>
                                        <Td xSmall={true} ySmall={true}>Ad spend, USD</Td>
                                        <Td xSmall={true} ySmall={true} textAlignment='text-right'>
                                            {numberFormat({
                                                userInput: (statsExtra.adCosts / stats['all'].customers_acquired),
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                    </tr>
                                    <tr className='font-medium total-row'>
                                        <Td xSmall={true} ySmall={true}>Ad Gained, USD</Td>
                                        {statsExtra.periods.map((p: number) => {
                                            return (
                                                <Td xSmall={true} ySmall={true} textAlignment={'text-right'}>{`${p + 1}`} period</Td>
                                            )
                                        })}
                                    </tr>
                                    <tr className='font-medium'>
                                        <Td xSmall={true} ySmall={true}>Gross Average Order Value</Td>
                                        <Td xSmall={true} ySmall={true} textAlignment='text-right'>
                                            {numberFormat({
                                                userInput: (stats['all'].initialTotal / stats['all'].customers_acquired),
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                    </tr>
                                    <tr className='font-medium '>
                                        <Td xSmall={true} ySmall={true}>Refunds ratio, %</Td>
                                        {statsExtra.periods.map((p: number) => {
                                            return (
                                                <Td xSmall={true} ySmall={true} textAlignment={'text-right'}>10%</Td>
                                            )
                                        })}
                                    </tr>
                                    {statsExtra.plans.map((pl: string) => {
                                        return (
                                            <tr key={`d-all-subs-rev-${pl}`}>
                                                <Td xSmall={true} ySmall={true}>---revenue from {pl} subs</Td>
                                                {statsExtra.periods.map((p: number) => {
                                                    return (
                                                        <Td xSmall={true} ySmall={true} textAlignment={'text-right'}>
                                                            {numberFormat({
                                                                userInput: (stats['all'].simulate_plan[pl][p] / stats['all'].customers_acquired),
                                                                decimals: 2,
                                                                decPoint: '.',
                                                                thousandsSep: ' '
                                                            })}
                                                        </Td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                    <tr className='font-medium'>
                                        <Td xSmall={true} ySmall={true}>Revenue Ad Gained, USD</Td>
                                        {statsExtra.periods.map((p: number) => {
                                            return (
                                                <Td xSmall={true} ySmall={true} textAlignment={'text-right'}>
                                                    {numberFormat({
                                                        userInput: (stats['all'].simulate[p] / stats['all'].customers_acquired),
                                                        decimals: 2,
                                                        decPoint: '.',
                                                        thousandsSep: ' '
                                                    })}
                                                </Td>
                                            )
                                        })}
                                    </tr>

                                    <tr className='font-bold'>
                                        <Td xSmall={true} ySmall={true}>Cumulative Revenue Ad Gained, USD</Td>
                                        <DrawFromTo
                                            data={
                                                statsExtra.periods.map((p: number) => (stats['all'].simulate[p] / stats['all'].customers_acquired))
                                            }
                                            start={0}
                                            precision={2}
                                            add={true}
                                        />
                                    </tr>

                                    <tr className='font-medium total-row'>
                                        <Td xSmall={true} ySmall={true}>ROAS</Td>
                                        <DrawFromTo
                                            data={
                                                statsExtra.periods.map((p: number) => (stats['all'].simulate[p] / stats['all'].customers_acquired))
                                            }
                                            start={0}
                                            precision={2}
                                            add={true}
                                            divider={(statsExtra.adCosts / stats['all'].customers_acquired)}
                                        />
                                    </tr>


                                    <tr className='font-medium'>
                                        <Td xSmall={true} ySmall={true}>Payback period forecast</Td>
                                        {statsExtra.periods.map((p: number) => {
                                            return (
                                                <Td xSmall={true} ySmall={true} textAlignment={'text-right'}>{`${p + 1}`} period</Td>
                                            )
                                        })}
                                    </tr>

                                    <tr >
                                        <Td xSmall={true} ySmall={true}>Cash Flow</Td>
                                        {statsExtra.periods.map((p: number) => {
                                            return (
                                                <Td xSmall={true} ySmall={true} textAlignment={'text-right'}>
                                                    {numberFormat({
                                                        userInput: ((stats['all'].simulate[p] - stats['all'].adCosts[p]) / stats['all'].customers_acquired),
                                                        decimals: 2,
                                                        decPoint: '.',
                                                        thousandsSep: ' '
                                                    })}
                                                </Td>
                                            )
                                        })}
                                    </tr>


                                    <tr className='font-bold'>
                                        <Td xSmall={true} ySmall={true}>Cumulative Cash Flow</Td>
                                        <DrawFromTo
                                            data={
                                                statsExtra.periods.map((p: number) => ((stats['all'].simulate[p] - stats['all'].adCosts[p]) / stats['all'].customers_acquired))
                                            }
                                            start={0}
                                            precision={2}
                                            add={true}
                                        />
                                    </tr>

                                </tbody>
                            }
                        />
                    </WhiteCard>}

            </div>
        </Fragment>
    )
}

type DrawFromToProps = {
    start: number,
    data: number[],
    precision?: number,
    add?: boolean,
    divider?: number
}
const DrawFromTo = (props: DrawFromToProps) => {
    let start = props.start;
    const divider = props.divider ? props.divider : 1;

    return <Fragment>
        {props.data.map((p: number, iId: number) => {
            if (props.add) {
                start += p;
            } else {
                start -= p;
            }
            return (<Td xSmall={true} ySmall={true} key={`dr-${iId}`} textAlignment='text-right'>
                {numberFormat({
                    userInput: (start / divider),
                    decimals: props.precision ? props.precision : 0,
                    decPoint: '.',
                    thousandsSep: ' '
                })}
            </Td>)
        })}
    </Fragment>
}


type DrawFromToChurnProps = {
    start: number,
    data: number[],
    precision?: number,
}
const DrawFromToChurn = (props: DrawFromToChurnProps) => {
    let start = props.start;
    let startX = 0;

    return <Fragment>
        {props.data.map((p: number, iId: number) => {
            startX += p;
            // start -= p;
            return (<Td xSmall={true} ySmall={true} key={`dr-${iId}`} textAlignment='text-right'>
                {numberFormat({
                    userInput: (startX / start * 100),
                    decimals: props.precision ? props.precision : 0,
                    decPoint: '.',
                    thousandsSep: ' '
                })}%
            </Td>)
        })}
    </Fragment>
}

type DrawFromToPercentProps = {
    start: number,
    startAll: number,
    data: number[],
    dataAll: number[],
    precision?: number,
    add?: boolean
}
const DrawFromToPercent = (props: DrawFromToPercentProps) => {
    let start = props.start;
    let startAll = props.startAll;

    return <Fragment>
        {props.data.map((p: number, iId: number) => {
            const pAll = props.dataAll[iId];

            if (props.add) {
                start += p;
                startAll += pAll;
            } else {
                start -= p;
                startAll -= pAll;
            }
            return (<Td xSmall={true} ySmall={true} key={`dr-${iId}`} textAlignment='text-right'>
                {numberFormat({
                    userInput: (start / startAll * 100),
                    decimals: props.precision ? props.precision : 0,
                    decPoint: '.',
                    thousandsSep: ' '
                })}%
            </Td>)
        })}
    </Fragment>
}