import React, { Fragment } from 'react'
import { MP } from '@newageerp/crm-ui'
import { useUIBuilder } from "@newageerp/v3.app.mvc.ui-builder";
import ProductPerformanceChartContainer from './ProductPerformanceChartContainer';
import { MainToolbarTitle } from '@newageerp/v3.bundles.layout-bundle';


export default function ProductPerformanceChart() {
    const { settings } = useUIBuilder();
    const dataSource = 'product-performance-ltv'
    return (
        <Fragment>
            <MainToolbarTitle title={"Weekly cumulative LTV"} />

            <MP.GlobalProvider settings={{
                apiKey: settings.mp.apiKey,
                baseUrl: settings.mp.baseUrl,
            }}>
                <MP.InfoProvider key={`dt-${dataSource}`} dataSource={dataSource} permissions={{
                    doFilter: false,
                }}>
                    <ProductPerformanceChartContainer />
                </MP.InfoProvider>
            </MP.GlobalProvider>
        </Fragment>
    )
}
