import ChargeData9515d from "./components/Payments/ChargeData";
import CardData3382d from "./components/Payments/CardData";
import PspData15c25 from "./components/Payments/PspData";
import OrchestratorDatacae42 from "./components/Payments/OrchestratorData";
import LinkToOrder19031 from "./components/Payments/LinkToOrder";
import RefundActionbcf17 from "./components/Payments/RefundAction";
import LinkToOrderb2178 from "./components/Subscription/LinkToOrder";
import Actions085e3 from "./components/SubscriptionPayment/Actions";
import StatusChange38657 from "./components/PaymentRefundRequest/StatusChange";
import Statusdb921 from "./components/SubscriptionCancel/Status";
import Actionec60b from "./components/ShippingOrder/Action";
export const CustomListComponentsMap: any = {
    'customlist.Payments/ChargeData': ChargeData9515d,
    'customlist.Payments/CardData': CardData3382d,
    'customlist.Payments/PspData': PspData15c25,
    'customlist.Payments/OrchestratorData': OrchestratorDatacae42,
    'customlist.Payments/LinkToOrder': LinkToOrder19031,
    'customlist.Payments/RefundAction': RefundActionbcf17,
    'customlist.Subscription/LinkToOrder': LinkToOrderb2178,
    'customlist.SubscriptionPayment/Actions': Actions085e3,
    'customlist.PaymentRefundRequest/StatusChange': StatusChange38657,
    'customlist.SubscriptionCancel/Status': Statusdb921,
    'customlist.ShippingOrder/Action': Actionec60b,
}