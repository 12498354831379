import { Card, MP, Table, Form, Button } from '@newageerp/crm-ui';
import React, { useEffect, useState } from 'react'
import { MpFilterStorageListResponseData } from "@newageerp/mp-types";

type Props = {
    reloadKey: number,
    selectedCharts: string[],
    setSelectedCharts: (v: string[]) => void,
    setAvailableFilters: (v: MpFilterStorageListResponseData[]) => void,
    setShowFiltersPopup: (v: boolean) => void,
}

export default function ProductPerformanceChartList(props: Props) {
    const { dataSource } = MP.useMpInfo();

    const onSelectChart = (title: string) => {
        const _selectedCharts: string[] = JSON.parse(JSON.stringify(props.selectedCharts));
        if (props.selectedCharts.indexOf(title) > -1) {
            props.setSelectedCharts(_selectedCharts.filter(t => t !== title));
        } else {
            props.setSelectedCharts([..._selectedCharts, title]);
        }
    }

    const [search, setSearch] = useState('');
    const [filterListRequest, { data: savedFilters }] = MP.filter.useFiltersList();

    const getFiltersList = async () => {
        await filterListRequest({
            dataSource,
            user: 'all'
        })
    }

    useEffect(() => {
        if (props.reloadKey > 0) {
            getFiltersList().catch(console.error)
        }
    }, [props.reloadKey]);

    useEffect(() => {
        props.setAvailableFilters(savedFilters);
    }, [savedFilters]);

    const tbl = Table.small;

    const filtersToShow = savedFilters.filter(f => {
        if (!search) return true;
        return f.title.toLowerCase().indexOf(search.toLowerCase()) > -1;
    })

    return (
        <Card.WhiteCard
            header={{
                title: 'Available filters',
                toolbar: <Card.toolbar.CardToolbar>
                    <div className='flex items-center justify-between w-full'>
                        <Card.toolbar.SearchBar value={search} onSearch={setSearch} />

                        <div>
                            <Button.ToolbarButton iconName='cogs' onClick={() => props.setShowFiltersPopup(true)}>Manage saved filters</Button.ToolbarButton>
                        </div>
                    </div>
                </Card.toolbar.CardToolbar>
            }}
        >
            <tbl.Table
                thead={
                    <thead>
                        <tr>
                            <tbl.Th>Filter</tbl.Th>
                            <tbl.Th props={{ className: 'text-right' }}>Action</tbl.Th>
                        </tr>
                    </thead>
                }
                tbody={
                    <tbody>
                        {filtersToShow.map((f, fIdx) => {
                            const isChecked = props.selectedCharts.indexOf(f.title) > -1;

                            return <tr key={`f-${fIdx}`}>
                                <tbl.Td>
                                    {f.title}
                                </tbl.Td>
                                <tbl.Td props={{ className: 'text-right' }}>
                                    <Form.Switch enabled={isChecked} setEnabled={() => onSelectChart(f.title)} />
                                </tbl.Td>
                            </tr>
                        })}
                    </tbody>
                }
            />

        </Card.WhiteCard>
    )
}
