import { Card, Window, Button, MP } from '@newageerp/crm-ui'
import React, { Fragment, useEffect, useState } from 'react'
import ProductPerformanceChartList from './ProductPerformanceChartList';
import ProductPerformanceChartChart from './ProductPerformanceChartChart';
import { MpFilterStorageListResponseData } from "@newageerp/mp-types";

export default function ProductPerformanceChartContainer() {
    const [reloadKey, setReloadKey] = useState(-1);

    const { settings } = MP.useMpInfo();
    const [showFiltersPopup, setShowFiltersPopup] = useState(false);
    const [selectedCharts, setSelectedCharts] = useState<string[]>([]);
    const [availableFilters, setAvailableFilters] = useState<MpFilterStorageListResponseData[]>([]);
    
    useEffect(() => {
        if (!showFiltersPopup) {
            setReloadKey(new Date().getTime());
        }
    }, [showFiltersPopup])

    if (settings.chart.get.length === 0) {
        return <Fragment />
    }

    return (
        <div>
            <div className='space-y-4'>
                <div className='space-y-2'>
                    <div className='w-full'>
                        <ProductPerformanceChartList
                            selectedCharts={selectedCharts}
                            setSelectedCharts={setSelectedCharts}
                            setAvailableFilters={setAvailableFilters}
                            setShowFiltersPopup={setShowFiltersPopup}
                            reloadKey={reloadKey}
                        />
                    </div>

                    <div className='w-full'>
                        <ProductPerformanceChartChart
                            setShowFiltersPopup={setShowFiltersPopup}
                            selectedCharts={selectedCharts}
                            availableFilters={availableFilters}
                        />
                    </div>

                </div>
            </div>
            <Window.Popup
                isPopup={showFiltersPopup}
                title="Filtes management"
                onClick={() => setShowFiltersPopup(false)}
                className='w-full h-[80vh]'
            >
                <MP.filter.SaveManagement />
            </Window.Popup>
        </div>
    )
}
