import React, { Fragment, useState } from 'react'
import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle'
import { useListDataSource } from '@newageerp/v3.app.list.list-data-source';
import { FileUploadWidget } from "@newageerp/v3.bundles.app-bundle";
import { useURequest } from "@newageerp/v3.bundles.hooks-bundle";
import { Window } from '@newageerp/crm-ui';

export default function ShippingOrderExport() {
    const listDataSource = useListDataSource();
    const [showImport, setShowImport] = useState(false);

    const exportData = () => {
        const link = `/app/plugins/ShipmentAmazon/export?token=${window.localStorage.getItem('token')}&listToken=${listDataSource.data.cache.token}`;
        window.open(link, '_blank');
    }


    return (
        <Fragment>
            <ToolbarButton iconName='download' onClick={exportData} />

            <ToolbarButton iconName='file-upload' onClick={() => setShowImport(true)} />

            {showImport &&

                <ImportPopup onClose={() => setShowImport(false)} />
            }
        </Fragment>
    )
}

type ImportPopupProps = {
    onClose: () => void,
}

const ImportPopup = (props: ImportPopupProps) => {
    const listDataSource = useListDataSource();

    const [doUpload, doUploadParams] = useURequest('/app/plugins/ShipmentAmazon/import');

    return (
        <Window.Popup isPopup={true} title="Amazon import" onClick={props.onClose}>
            <div className="space-y-2">
                <FileUploadWidget
                    type={"payments-import"}
                    onUpload={(nfiles: any) => {
                        Object.keys(nfiles).map((k: string) => {
                            const f = nfiles[k];
                            doUpload({ ...f, toDb: true }).then((res: any) => {
                                listDataSource.data.reload.do();
                                props.onClose();
                            });
                        });
                    }}
                />
                {doUploadParams.loading && <p>Loading...</p>}
            </div>
        </Window.Popup>

    )
}