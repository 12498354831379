import React, { Fragment } from 'react';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';

export const checkUserPermission = (userState: any, permission: string) => {
    return userState.scopes.indexOf(permission) >= 0;
};

interface ICheckUserPermissionComponent {
    children: any,
    permissions?: ENaeSPermissions[],
    permissionsStr?: string,
}
export const CheckUserPermissionComponent = (props: ICheckUserPermissionComponent) => {
    const {userState} = useTemplatesCore()

    const permissions = props.permissions?props.permissions:(props.permissionsStr?JSON.parse(props.permissionsStr):undefined);

    if (!permissions) {
      return <Fragment />
    }

    let isOk = false;
    permissions.forEach((permission: ENaeSPermissions) => {
        if (checkUserPermission(userState, permission)) {
            isOk = true;
        }
    });
    if (!isOk) {
        return <Fragment />
    }
    return props.children;
}

export const checkDefault = (userState: any) => {
    return userState.scopes.indexOf("default") >= 0;
};
export const checkAdmin = (userState: any) => {
    return userState.scopes.indexOf("admin") >= 0;
};
export const checkSupport = (userState: any) => {
    return userState.scopes.indexOf("support") >= 0;
};
export const checkSupport_head = (userState: any) => {
    return userState.scopes.indexOf("support_head") >= 0;
};
export const checkMedia_buyer = (userState: any) => {
    return userState.scopes.indexOf("media_buyer") >= 0;
};
export const checkProduct_lead = (userState: any) => {
    return userState.scopes.indexOf("product_lead") >= 0;
};
export const checkAdditionalsubs = (userState: any) => {
    return userState.scopes.indexOf("additionalsubs") >= 0;
};
export const checkTiktok = (userState: any) => {
    return userState.scopes.indexOf("tiktok") >= 0;
};
export const checkNewsletter = (userState: any) => {
    return userState.scopes.indexOf("newsletter") >= 0;
};
export const checkNone = (userState: any) => {
    return userState.scopes.indexOf("none") >= 0;
};
export const checkDev = (userState: any) => {
    return userState.scopes.indexOf("dev") >= 0;
};
export const checkGoogle = (userState: any) => {
    return userState.scopes.indexOf("google") >= 0;
};
export const checkMonitoring = (userState: any) => {
    return userState.scopes.indexOf("monitoring") >= 0;
};export enum ENaeSPermissions {
Default = "default",
Admin = "admin",
Support = "support",
Support_head = "support_head",
Media_buyer = "media_buyer",
Product_lead = "product_lead",
Additionalsubs = "additionalsubs",
Tiktok = "tiktok",
Newsletter = "newsletter",
None = "none",
Dev = "dev",
Google = "google",
Monitoring = "monitoring",}
export const NaeSPermissions = [
    {
        "key": "default",
        "slug": "default",
        "title": "default"
    },
    {
        "key": "admin",
        "slug": "admin",
        "title": "Admin"
    },
    {
        "key": "support",
        "slug": "support",
        "title": "Support"
    },
    {
        "key": "support_head",
        "slug": "support_head",
        "title": "Support Head"
    },
    {
        "key": "media_buyer",
        "slug": "media_buyer",
        "title": "Media Buyer"
    },
    {
        "key": "product_lead",
        "slug": "product_lead",
        "title": "Product Lead"
    },
    {
        "key": "additionalsubs",
        "slug": "additionalsubs",
        "title": "Additional subs"
    },
    {
        "key": "tiktok",
        "slug": "tiktok",
        "title": "Tiktok"
    },
    {
        "key": "newsletter",
        "slug": "newsletter",
        "title": "Newsletter"
    },
    {
        "key": "none",
        "slug": "none",
        "title": "None"
    },
    {
        "key": "dev",
        "slug": "dev",
        "title": "DEV"
    },
    {
        "key": "google",
        "slug": "google",
        "title": "Google"
    },
    {
        "key": "monitoring",
        "slug": "monitoring",
        "title": "Monitoring"
    }
]