import React, { Fragment, useEffect, useState } from 'react'
import { MP } from '@newageerp/crm-ui'
import { DashboardGrossCustomAdSpendItem, DashboardGrossCustomConfig, DashboardGrossCustomFilter, DashboardGrossCustomGrossPerfItem, DashboardGrossCustomGroupBy } from './types';
import { Table, Card } from '@newageerp/crm-ui';
import { numberFormat } from '@newageerp/v3.bundles.utils-bundle';
import classNames from 'classnames';
import { CrmGrossFields, CrmGrossFrom, DashboardGrossCustomDataAggregate, DashboardGrossCustomDataFilter, DashboardGrossCustomDataSort, DashboardGrossCustomRecurringFilter, DashboardGrossCustomRecurringGroupByPath } from './utils';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { checkAdmin } from '../../../_generated/_custom/config/NaeSPermissions';

type Props = {
    groupBy: DashboardGrossCustomGroupBy,
    config: DashboardGrossCustomConfig,
    reloadKey: number
    filter: DashboardGrossCustomFilter,
    loading: {
        get: boolean,
        set: (v: boolean) => void,
    }
}

export default function DashboardGrossCustomPivotFunnel(props: Props) {
    const { userState } = useTemplatesCore();
    const isAdmin = checkAdmin(userState);
    
    const [doGrossPerfReq, grosPerfData] = MP.useMpDataRequest(CrmGrossFrom);
    const [doRecurringReq, recurringData] = MP.useMpDataRequest<DashboardGrossCustomAdSpendItem>('payment-recurring-report');

    const [data, setData] = useState<DashboardGrossCustomGrossPerfItem[]>([])

    const loadGrossPerformanceData = async () => {

        if (props.config.filters.recurring) {
            const groupByColumn = DashboardGrossCustomRecurringGroupByPath(props.groupBy);
            await doRecurringReq({
                limit: -1,
                offset: 0,
                aggregate: [
                    {
                        title: "",
                        function: 'countDistinct',
                        column: 'order_id'
                    },
                    {
                        title: "",
                        function: 'sum',
                        column: 'total'
                    }
                ],
                columns: [
                    groupByColumn
                ],
                dataFilter: DashboardGrossCustomRecurringFilter(props.config, props.filter),
                sort: [
                    {
                        "column": groupByColumn,
                        "direction": "ASC"
                    }
                ]
            })
        }

        if (props.config.filters.gross) {
            const res = await doGrossPerfReq({
                limit: -1,
                offset: 0,
                aggregate: DashboardGrossCustomDataAggregate,
                columns: [props.groupBy],
                dataFilter: DashboardGrossCustomDataFilter(props.config, props.filter),
                sort: DashboardGrossCustomDataSort(props.groupBy),
                fields: CrmGrossFields,
            })
            const _data: DashboardGrossCustomGrossPerfItem[] = res.data.data;
            _data.sort((a, b) => {
                if (a.report_item_payment.gross_total_sum > b.report_item_payment.gross_total_sum) {
                    return -1;
                }
                if (a.report_item_payment.gross_total_sum < b.report_item_payment.gross_total_sum) {
                    return 1;
                }
                return 0;
            })
            setData(_data);
        }
    }

    useEffect(() => {
        props.loading.set(grosPerfData.loading || recurringData.loading)
    }, [grosPerfData.loading, recurringData.loading]);

    useEffect(() => {
        loadGrossPerformanceData().catch(console.error)
    }, [props.config, props.groupBy, props.reloadKey]);

    const tbl = Table.default;

    return (
        <Card.WhiteCard
            style={{
                body: {
                    paddingHorizontal: 'p-2'
                }
            }}
        >
            <tbl.Table
                thead={
                    <thead>
                        <tr className='sticky top-0  bg-white'>
                            <tbl.Th props={{ className: classNames('sticky left-0', borderBR, ' bg-white') }}>{""}</tbl.Th>

                            <tbl.Th props={{ className: classNames('text-right', borderBottom) }}>Initial</tbl.Th>

                            <tbl.Th props={{ className: classNames('text-right', borderBL) }}>Initial (subs)</tbl.Th>
                            <tbl.Th props={{ className: classNames('text-right', borderBottom) }}>Initial (lifetime)</tbl.Th>
                            <tbl.Th props={{ className: classNames('text-right', borderBottom) }}>Initial (upsells)</tbl.Th>

                            <tbl.Th props={{ className: classNames('text-right', borderBL) }}>Subscriptions</tbl.Th>


                            <tbl.Th props={{ className: classNames('text-right', borderBL) }}>AOV</tbl.Th>

                            {isAdmin && <Fragment>
                            <tbl.Th props={{ className: classNames('text-right', borderBL) }}>Recurring</tbl.Th>
                            </Fragment>}

                        </tr>
                    </thead>
                }
                tbody={
                    <tbody>
                        {data.map(el => {
                            const id = getValueByPath(el, props.groupBy);

                            const recurring = recurringData.data.filter(a => getValueByPath(a, DashboardGrossCustomRecurringGroupByPath(props.groupBy)) === id).map(a => a.total_sum).reduce((a, b) => a + b, 0);


                            const aov = el.id_count > 0 ? el.report_item_payment.gross_total_sum / el.id_count : 0;

                            return (
                                <tr key={`row-${id}`}>
                                    <tbl.Td props={{ className: classNames('sticky left-0', borderRight, ' bg-white') }}>{id}</tbl.Td>

                                    <tbl.Td props={{ className: classNames('text-right') }}>{floatFormat(el.report_item_payment.gross_total_sum)}</tbl.Td>

                                    <tbl.Td props={{ className: classNames('text-right', borderLeft) }}>{floatFormat(el.report_item_payment.gross_initial_sub_total_sum - el.report_item_payment.gross_initial_lifetime_total_sum)}</tbl.Td>
                                    <tbl.Td props={{ className: classNames('text-right') }}>{floatFormat(el.report_item_payment.gross_initial_lifetime_total_sum)}</tbl.Td>
                                    <tbl.Td props={{ className: classNames('text-right') }}>{floatFormat(el.report_item_payment.gross_upsell_total_sum)}</tbl.Td>

                                    <tbl.Td props={{ className: classNames('text-right', borderLeft) }}>{intFormat(el.id_count)}</tbl.Td>


                                    <tbl.Td props={{ className: classNames('text-right', borderLeft) }}>{floatFormat(aov)}</tbl.Td>

                                    {isAdmin && <Fragment>
                                    <tbl.Td props={{ className: classNames('text-right', borderLeft) }}>{floatFormat(recurring)}</tbl.Td>
                                    </Fragment>}


                                </tr>
                            )
                        })}
                        <OtherLine
                            data={data}
                            recurringData={recurringData.data}
                            groupBy={props.groupBy}
                        />
                        <TotalLine
                            data={data}
                            recurringData={recurringData.data}
                        />
                    </tbody>
                }
            />
        </Card.WhiteCard>
    )
}

type OtherLineProps = {
    data: DashboardGrossCustomGrossPerfItem[],
    recurringData: DashboardGrossCustomAdSpendItem[],
    groupBy: DashboardGrossCustomGroupBy,
}

const OtherLine = ({ recurringData, data, groupBy }: OtherLineProps) => {
    const { userState } = useTemplatesCore();
    const isAdmin = checkAdmin(userState);

    const tbl = Table.default;

    const ids = data.map(el => getValueByPath(el, groupBy))

    const recurring = recurringData.filter(a => ids.indexOf(getValueByPath(a, DashboardGrossCustomRecurringGroupByPath(groupBy))) === -1).map(a => a.total_sum).reduce((a, b) => a + b, 0);

    return (
        <tr className='italic'>
            <tbl.Td props={{ className: classNames('sticky left-0', borderRight) }}>{"Other"}</tbl.Td>

            <tbl.Td props={{ className: classNames('text-right', ) }}>{floatFormat(0)}</tbl.Td>

            <tbl.Td props={{ className: classNames('text-right', borderLeft) }}>{floatFormat(0)}</tbl.Td>
            <tbl.Td props={{ className: classNames('text-right', ) }}>{floatFormat(0)}</tbl.Td>
            <tbl.Td props={{ className: classNames('text-right', ) }}>{floatFormat(0)}</tbl.Td>

            <tbl.Td props={{ className: classNames('text-right', borderLeft) }}>{intFormat(0)}</tbl.Td>


            <tbl.Td props={{ className: classNames('text-right', borderLeft) }}>{floatFormat(0)}</tbl.Td>

            {isAdmin && <Fragment>
            <tbl.Td props={{ className: classNames('text-right', borderLeft) }}>{floatFormat(recurring)}</tbl.Td>
            </Fragment>}

        </tr>
    )
}

type TotalLineProps = {
    data: DashboardGrossCustomGrossPerfItem[],
    recurringData: DashboardGrossCustomAdSpendItem[],
}

const TotalLine = ({ data, recurringData }: TotalLineProps) => {
    const { userState } = useTemplatesCore();
    const isAdmin = checkAdmin(userState);

    const tbl = Table.default;

    const allGrossTotalSum = data.map(el => el.report_item_payment.gross_total_sum).reduce((a, b) => a + b, 0)
    const allGrossInitialSubTotalSum = data.map(el => el.report_item_payment.gross_initial_sub_total_sum).reduce((a, b) => a + b, 0)
    const allGrossInitialLifetimeTotalSum = data.map(el => el.report_item_payment.gross_initial_lifetime_total_sum).reduce((a, b) => a + b, 0)
    const allGrossInitialUpsellTotalSum = data.map(el => el.report_item_payment.gross_upsell_total_sum).reduce((a, b) => a + b, 0)

    const allSubs = data.map(el => el.id_count).reduce((a, b) => a + b, 0)

    const aov = allSubs > 0 ? allGrossTotalSum / allSubs : 0;

    const recurring = recurringData.map(a => a.total_sum).reduce((a, b) => a + b, 0);

    return (
        <tr className='font-bold'>
            <tbl.Td props={{ className: classNames('sticky left-0', borderTR) }}>{"Total"}</tbl.Td>

            <tbl.Td props={{ className: classNames('text-right', borderTop) }}>{floatFormat(allGrossTotalSum)}</tbl.Td>

            <tbl.Td props={{ className: classNames('text-right', borderTL) }}>{floatFormat(allGrossInitialSubTotalSum - allGrossInitialLifetimeTotalSum)}</tbl.Td>
            <tbl.Td props={{ className: classNames('text-right', borderTop) }}>{floatFormat(allGrossInitialLifetimeTotalSum)}</tbl.Td>
            <tbl.Td props={{ className: classNames('text-right', borderTop) }}>{floatFormat(allGrossInitialUpsellTotalSum)}</tbl.Td>

            <tbl.Td props={{ className: classNames('text-right', borderTL) }}>{intFormat(allSubs)}</tbl.Td>


            <tbl.Td props={{ className: classNames('text-right', borderTL) }}>{floatFormat(aov)}</tbl.Td>

            {isAdmin && <Fragment>
            <tbl.Td props={{ className: classNames('text-right', borderTL) }}>{floatFormat(recurring)}</tbl.Td>
            </Fragment>}

        </tr>
    )
}

export const getValueByPath = (el: any, path: string) => {
    let value: any = "";
    try {
        value = path.split('.').reduce((previous: any, current: any) => previous[current], el);
    } catch (e) {
    }
    return value;
}

const floatFormat = (value: number) => numberFormat({
    userInput: value,
    decimals: 2,
    decPoint: '.',
    thousandsSep: ' '
})

const intFormat = (value: number) => numberFormat({
    userInput: value,
    decimals: 0,
    decPoint: '.',
    thousandsSep: ' '
})

const borderRight = 'shadow-[inset_-1px_0px_0px_0px_rgba(96,165,250,1)]';
const borderLeft = 'shadow-[inset_1px_0px_0px_0px_rgba(96,165,250,1)]';
const borderTL = 'shadow-[inset_1px_1px_0px_0px_rgba(96,165,250,1)]';
const borderTR = 'shadow-[inset_-1px_1px_0px_0px_rgba(96,165,250,1)]';
const borderTop = 'shadow-[inset_0px_1px_0px_0px_rgba(96,165,250,1)]';
const borderBottom = 'shadow-[inset_0px_-1px_0px_0px_rgba(96,165,250,1)]';
const borderBL = 'shadow-[inset_1px_-1px_0px_0px_rgba(96,165,250,1)]';
const borderBR = 'shadow-[inset_-1px_-1px_0px_0px_rgba(96,165,250,1)]';