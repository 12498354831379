import React from "react";

import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { ToolbarButton } from "@newageerp/v3.bundles.buttons-bundle";
import { useListDataSource } from "@newageerp/v3.app.list.list-data-source";
import { useURequest } from "@newageerp/v3.bundles.hooks-bundle";
import { NaePathsMap } from "../../_generated/_custom/config/NaePaths";


export default function QuizResultStats() {
    const { data: tData } = useTemplatesLoader();
    const listDataSource = useListDataSource();

    const [doExport, doExportParams] = useURequest(NaePathsMap.post['pluginsQuizResultStatExport']);

    const onClick = () => {
        const filter = listDataSource.filter.prepare();
        doExport({
            filter,
            schema: "quiz-result",
        }).then((res: any) => {
            window.open(res.data.fileName, '_blank');
        })
    };
    return (
        <ToolbarButton
            title={"Stats"}
            onClick={onClick}
            iconName={"chart-pie"}
            loading={doExportParams.loading}
        />
    );
}
