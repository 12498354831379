import React, { Fragment } from 'react'
import { useListTableRow } from '@newageerp/v3.bundles.app-bundle';
import { MainButtonWithSave } from '@newageerp/v3.bundles.buttons-bundle';

interface Props {
    fieldKey: string,
}

export default function Status(props: Props) {
    const { element } = useListTableRow();

    if (element.status !== 0) {
        return <Fragment/>
    }

    return (
        <div className='flex gap-2'>
            <MainButtonWithSave
                elementId={element.id}
                saveData={{
                    status: 20
                }}
                schema={"subscription-cancel-request"}
                color='teal'
                confirmation={true}
            >
                Manual fixed
            </MainButtonWithSave>

        </div>
    )
}
