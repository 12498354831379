import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import { Compact, CompactRow, FieldDateRange, FieldLabel } from '@newageerp/v3.bundles.form-bundle';
import { useTranslation } from 'react-i18next';

import { MainToolbarTitle } from '@newageerp/v3.bundles.layout-bundle';
import { MainButton, ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';

import classNames from 'classnames'
import { useLocalStorage, useUList, useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../_generated/_custom/config/NaePaths';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import FunnelFilter from './Components/FunnelFilter';
import GrossV2MainTable from './GrossV2/GrossV2MainTable';
import { checkAdditionalsubs, checkAdmin, checkDev, checkGoogle, checkMonitoring, checkNewsletter, checkNone, checkSupport, checkSupport_head, checkTiktok } from '../../_generated/_custom/config/NaeSPermissions';
import CountryFilter, { CountryFilterCountry } from './Components/CountryFilter';
import CountryGroupFilter, { CountryGroupFilterGroup } from './Components/CountryGroupFilter';
import LanguageFilter from './Components/LanguageFilter';
import GrossV2OrderItems from './GrossV2/GrossV2OrderItems';
import AdSourceFilter from './Components/AdSourceFilter';

const uri = 'data:application/vnd.ms-excel;base64,';

const template =
    '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
    'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
    'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
    'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
    '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
    'xml><![endif]--></head><body>{table}</body></html>';

const base64 = (s: string) => {
    return window.btoa(unescape(encodeURIComponent(s)));
}

const doFormat = (s: string, tableId: string) => {
    const context = {
        worksheet: 'Worksheet',
        // @ts-ignore
        table: window.document.getElementById(tableId).outerHTML,
    };

    // @ts-ignore
    return s.replace(/{(\w+)}/g, (_m, p) => context[p]);
}

const doDownload = (tableId: string) => {
    const element = window.document.createElement('a');
    element.href = uri + base64(doFormat(template, tableId));
    element.download = 'export.xls';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

export default function DashboardGrossV2() {
    const { t } = useTranslation();
    const { userState } = useTemplatesCore();

    const isAdmin = checkAdmin(userState);
    const isSupport = checkSupport(userState);
    const isSupportHead = checkSupport_head(userState);
    const isNewsletter = checkNewsletter(userState);
    const isTikTok = checkTiktok(userState);
    const isGoogle = checkGoogle(userState);
    const isAdditionalSubs = checkAdditionalsubs(userState);
    const isNone = checkNone(userState) || checkMonitoring(userState);
    const isDev = checkDev(userState);


    const [countryGroup, setCountryGroup] = useState(0);

    const [loadState, setLoadState] = useState(0);
    const [lang, setLang] = useState('');
    const [funnels, setFunnels] = useLocalStorage('DashboardFunnelFilter', []);
    const [adSources, setAdSources] = useLocalStorage('DashboardAdSourceFilter', []);
    const [countries, setCountries] = useState<string[]>([]);

    const [lastReload, setLastReload] = useState('');
    const [groupBy, setGroupBy] = useState('date');

    const defDates = {
        dateFrom: moment().add(-10, 'day').format("YYYY-MM-DD"),
        dateTo: moment().add(1, 'day').format("YYYY-MM-DD")
    };
    const [chartDates, setChartDates] = useState(defDates);
    const clearState = () => {
        setChartDates(defDates)
    }

    const [countriesReq, countriesData] = useUList<CountryFilterCountry>("country", ['id', 'name']);
    const [countryGroupsReq, countryGroupsData] = useUList<CountryGroupFilterGroup>("country-group", ['id', 'name', 'countries', 'countriesExclude']);

    useEffect(() => {
        countriesReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
        countryGroupsReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
    }, []);

    // const [stats, setStats] = useState();
    // const [dates, setDates] = useState({ today: '', yesterday: '', week: '', month: '' });

    const [stats, setStats] = useState<any>({});
    const [statsExtra, setStatsExtra] = useState<any>({});

    // const [reqData, reqDataParams] = OpenApi.useURequest('pluginsDashboardStats');
    const [reqChartsData, reqDataChartsParams] = useURequest(NaePathsMap.post.GrossCalculateV2);

    const loadCharData = () => {
        setLoadState((new Date()).getTime())
        if (groupBy === 'order-item') {
            return;
        }
        reqChartsData({
            dateFrom: chartDates.dateFrom,
            dateTo: chartDates.dateTo,
            lang,
            funnels,
            countries,
            groupBy,
            adSources,
        }).then((res: any) => {
            setStats(res.data.reports);
            setStatsExtra(res.data.extra);
            setLastReload(moment().format('YYYY-MM-DD HH:mm'));
        })
    }

    useEffect(() => {
        loadCharData();
    }, [groupBy]);


    useEffect(() => {
        const t = setInterval(() => {
            // loadData();
            console.log('FIRED');
            if (!reqDataChartsParams.loading) {
                loadCharData();
            }
        }, 60 * 1000);
        return () => {
            clearInterval(t);
        }
    }, [chartDates, lang, funnels, countries, groupBy, adSources]);

    const showExtra = funnels.length === 0 && groupBy !== 'funnel' && groupBy !== 'funnel_part';

    if (isSupport || isSupportHead || isTikTok || isGoogle || isAdditionalSubs || isNone || isDev) {
        return <Fragment />
    }

    return (
        <Fragment>
            <MainToolbarTitle title={lastReload} />
            <div className='space-y-4'>
                <WhiteCard isCompact={true}>
                    <div className='flex gap-4 items-end'>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Date')}</FieldLabel>}
                                control={
                                    <FieldDateRange dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />
                                }
                            />
                        </Compact>
                        <CountryFilter
                            countries={countries}
                            countriesData={countriesData.data.data}
                            setCountries={setCountries}
                        />
                        <CountryGroupFilter
                            countriesData={countriesData.data.data}
                            countryGroup={countryGroup}
                            countryGroupsData={countryGroupsData.data.data}
                            setCountries={setCountries}
                            setCountryGroup={setCountryGroup}
                        />


                    </div>
                    <div className='flex gap-4 items-end'>
                        <LanguageFilter
                            lang={lang}
                            setLang={setLang}
                        />

                        <FunnelFilter
                            funnels={funnels}
                            setFunnels={setFunnels}
                        />

                        <AdSourceFilter
                            adSources={adSources}
                            setAdSources={setAdSources}
                        />

                        <div>
                            <ToolbarButton
                                iconName={"file-excel"}
                                onClick={() => doDownload('gross-pl-export')}
                            />
                        </div>
                    </div>
                    <div>
                        <MainButton color={"sky"} loading={reqDataChartsParams.loading} iconName='filter' disabled={reqDataChartsParams.loading} onClick={loadCharData}>Filter</MainButton>
                    </div>
                </WhiteCard>


                {!!statsExtra && statsExtra.loaded &&
                    <WhiteCard isCompact={true}>
                        <div className="w-full">
                            <div className={classNames('border-b', 'border-slate-200')}>
                                {DashboardGrossV2groupByTabs.map((t) => {
                                    const active = t.key === groupBy;
                                    return (
                                        <button
                                            key={`btn-${t.key}`}
                                            className={
                                                classNames(
                                                    'text-sm',
                                                    { 'font-semibold': active },
                                                    'px-[16px]',
                                                    'py-[8px]',
                                                    { 'text-sky-500': active },
                                                    { 'text-slate-500': !active },
                                                )
                                            }
                                            onClick={() => setGroupBy(t.key)}
                                            style={active ? { boxShadow: 'inset 0px -2px 0px 0px #0EA5E9' } : undefined}
                                        >
                                            {t.title}
                                        </button>
                                    )
                                })}
                            </div>
                        </div>
                        {groupBy === 'order-item' && <GrossV2OrderItems
                            date={{
                                from: chartDates.dateFrom,
                                to: chartDates.dateTo,
                            }}
                            loadState={loadState}
                            funnels={funnels}
                            countries={countries}
                            adSources={adSources}
                        />}
                        {groupBy !== 'order-item' &&
                            <GrossV2MainTable
                                key={groupBy}
                                groupBy={groupBy}
                                showExtra={showExtra}
                                stats={stats}
                                statsExtra={statsExtra}
                            />
                        }
                    </WhiteCard>
                }
            </div>
        </Fragment>
    )
}

export const DashboardGrossV2borderRight = 'shadow-[inset_-1px_0px_0px_0px_rgba(96,165,250,1)]';
export const DashboardGrossV2borderLeft = 'shadow-[inset_1px_0px_0px_0px_rgba(96,165,250,1)]';

export const DashboardGrossV2groupByTabs = [
    {
        title: 'Date',
        key: 'date'
    },
    {
        title: 'Country',
        key: 'country'
    },
    {
        title: 'Country group',
        key: 'country_group'
    },
    {
        title: 'Ad source',
        key: 'ad_source'
    },
    {
        title: 'Ad source (adopted)',
        key: 'ad_source_adopted'
    },
    {
        title: 'Funnel',
        key: 'funnel'
    },
    {
        title: 'Funnel part',
        key: 'funnel_part'
    },
    {
        title: 'Order items',
        key: 'order-item'
    }
]