import { INaeSchema } from "@newageerp/v3.app.main-bundle"
export const NaeSSchema: INaeSchema[] = [
    {
        "className": "AdCost",
        "schema": "ad-cost",
        "title": "Ad cost",
        "titlePlural": "Ad costs",
        "required": [
            "date",
            "total"
        ],
        "scopes": []
    },
    {
        "className": "AdaloUser",
        "schema": "adalo-user",
        "title": "Adalo user",
        "titlePlural": "Adalo users",
        "required": [],
        "scopes": []
    },
    {
        "className": "AnalyticsViews",
        "schema": "analytics-views",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "Country",
        "schema": "country",
        "title": "Country",
        "titlePlural": "Countries",
        "required": [],
        "scopes": []
    },
    {
        "className": "CountryGroup",
        "schema": "country-group",
        "title": "Country group",
        "titlePlural": "Country groups",
        "required": [],
        "scopes": []
    },
    {
        "className": "Customer",
        "schema": "customer",
        "title": "Customer",
        "titlePlural": "Customers",
        "required": [],
        "scopes": []
    },
    {
        "className": "Frontend",
        "schema": "frontend",
        "title": "Frontend",
        "titlePlural": "Frontend",
        "required": [],
        "scopes": []
    },
    {
        "className": "KlavyioEventSync",
        "schema": "klavyio-event-sync",
        "title": "Klavyio events sync",
        "titlePlural": "Klavyio events sync",
        "required": [],
        "scopes": []
    },
    {
        "className": "MonitoringItem",
        "schema": "monitoring-item",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "OrderItem",
        "schema": "order-item",
        "title": "Order item",
        "titlePlural": "Order items",
        "required": [],
        "scopes": []
    },
    {
        "className": "OrderItemSkuGroup",
        "schema": "order-item-sku-group",
        "title": "Order item group",
        "titlePlural": "Order item groups",
        "required": [
            "title",
            "sku"
        ],
        "scopes": []
    },
    {
        "className": "Payment",
        "schema": "payment",
        "title": "Payment",
        "titlePlural": "Payments",
        "required": [],
        "scopes": []
    },
    {
        "className": "PaymentCardInfo",
        "schema": "payment-card-info",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "PaymentChargeInfo",
        "schema": "payment-charge-info",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "PaymentOrchestrator",
        "schema": "payment-orchestrator",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "PaymentPsp",
        "schema": "payment-psp",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "PaymentRefundInfo",
        "schema": "payment-refund-info",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "PaymentRefundRequest",
        "schema": "payment-refund-request",
        "title": "Refund request",
        "titlePlural": "Refund requests",
        "required": [
            "payment",
            "total"
        ],
        "scopes": []
    },
    {
        "className": "PaymentTaxInfo",
        "schema": "payment-tax-info",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "QuizResult",
        "schema": "quiz-result",
        "title": "Quiz result",
        "titlePlural": "Quiz results",
        "required": [],
        "scopes": []
    },
    {
        "className": "ReportItem",
        "schema": "report-item",
        "title": "Order",
        "titlePlural": "Orders",
        "required": [],
        "scopes": []
    },
    {
        "className": "ReportItemAdSource",
        "schema": "report-item-ad-source",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "ReportItemFunnel",
        "schema": "report-item-funnel",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "ReportItemPayment",
        "schema": "report-item-payment",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "ReportItemReturn",
        "schema": "report-item-return",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "ReportUpsellItem",
        "schema": "report-upsell-item",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfExploreDataFolder",
        "schema": "sf-explore-data-folder",
        "title": "Explore data folder",
        "titlePlural": "Explore data folder",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfExploreDataItem",
        "schema": "sf-explore-data-item",
        "title": "Explore data",
        "titlePlural": "Explore data",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "ShippingOrder",
        "schema": "shipping-order",
        "title": "Shipping order",
        "titlePlural": "Shipping orders",
        "required": [],
        "scopes": []
    },
    {
        "className": "Subscription",
        "schema": "subscription",
        "title": "Subscription",
        "titlePlural": "Subscriptions",
        "required": [],
        "scopes": []
    },
    {
        "className": "SubscriptionCancel",
        "schema": "subscription-cancel",
        "title": "Cancellations",
        "titlePlural": "Cancellations",
        "required": [],
        "scopes": []
    },
    {
        "className": "SubscriptionCancelReason",
        "schema": "subscription-cancel-reason",
        "title": "Subscription Cancel Reason",
        "titlePlural": "Subscription Cancel Reasons",
        "required": [
            "title"
        ],
        "scopes": []
    },
    {
        "className": "SubscriptionCancelRequest",
        "schema": "subscription-cancel-request",
        "title": "Cancellations (WEB)",
        "titlePlural": "Cancellations (WEB)",
        "required": [],
        "scopes": []
    },
    {
        "className": "SubscriptionPayment",
        "schema": "subscription-payment",
        "title": "Recurring payment",
        "titlePlural": "Recurring payments",
        "required": [],
        "scopes": []
    },
    {
        "className": "SubscriptionPrice",
        "schema": "subscription-price",
        "title": "Subscription price",
        "titlePlural": "Subscription price",
        "required": [
            "subscription",
            "dateFrom"
        ],
        "scopes": []
    },
    {
        "className": "SubscriptionSupportReport",
        "schema": "subscription-support-report",
        "title": "Subscription report",
        "titlePlural": "Subscription report",
        "required": [],
        "scopes": []
    },
    {
        "className": "User",
        "schema": "user",
        "title": "User",
        "titlePlural": "Users",
        "required": [],
        "scopes": []
    },
    {
        "className": "WebhookProcessing",
        "schema": "webhook-processing",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    }
]
        export const NaeSSchemaMap = {
    "User": {
        "className": "User",
        "schema": "user"
    },
    "Frontend": {
        "className": "Frontend",
        "schema": "frontend"
    },
    "QuizResult": {
        "className": "QuizResult",
        "schema": "quiz-result"
    },
    "Customer": {
        "className": "Customer",
        "schema": "customer"
    },
    "Payment": {
        "className": "Payment",
        "schema": "payment"
    },
    "AdCost": {
        "className": "AdCost",
        "schema": "ad-cost"
    },
    "Subscription": {
        "className": "Subscription",
        "schema": "subscription"
    },
    "SubscriptionCancel": {
        "className": "SubscriptionCancel",
        "schema": "subscription-cancel"
    },
    "SubscriptionPayment": {
        "className": "SubscriptionPayment",
        "schema": "subscription-payment"
    },
    "PaymentRefundRequest": {
        "className": "PaymentRefundRequest",
        "schema": "payment-refund-request"
    },
    "Country": {
        "className": "Country",
        "schema": "country"
    },
    "AdaloUser": {
        "className": "AdaloUser",
        "schema": "adalo-user"
    },
    "CountryGroup": {
        "className": "CountryGroup",
        "schema": "country-group"
    },
    "WebhookProcessing": {
        "className": "WebhookProcessing",
        "schema": "webhook-processing"
    },
    "ReportItem": {
        "className": "ReportItem",
        "schema": "report-item"
    },
    "ReportUpsellItem": {
        "className": "ReportUpsellItem",
        "schema": "report-upsell-item"
    },
    "OrderItem": {
        "className": "OrderItem",
        "schema": "order-item"
    },
    "MonitoringItem": {
        "className": "MonitoringItem",
        "schema": "monitoring-item"
    },
    "SubscriptionCancelReason": {
        "className": "SubscriptionCancelReason",
        "schema": "subscription-cancel-reason"
    },
    "OrderItemSkuGroup": {
        "className": "OrderItemSkuGroup",
        "schema": "order-item-sku-group"
    },
    "SubscriptionPrice": {
        "className": "SubscriptionPrice",
        "schema": "subscription-price"
    },
    "SubscriptionCancelRequest": {
        "className": "SubscriptionCancelRequest",
        "schema": "subscription-cancel-request"
    },
    "SubscriptionSupportReport": {
        "className": "SubscriptionSupportReport",
        "schema": "subscription-support-report"
    },
    "KlavyioEventSync": {
        "className": "KlavyioEventSync",
        "schema": "klavyio-event-sync"
    },
    "SfKeyValueOrm": {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm"
    },
    "ReportItemPayment": {
        "className": "ReportItemPayment",
        "schema": "report-item-payment"
    },
    "AnalyticsViews": {
        "className": "AnalyticsViews",
        "schema": "analytics-views"
    },
    "SfExploreDataItem": {
        "className": "SfExploreDataItem",
        "schema": "sf-explore-data-item"
    },
    "SfExploreDataFolder": {
        "className": "SfExploreDataFolder",
        "schema": "sf-explore-data-folder"
    },
    "PaymentTaxInfo": {
        "className": "PaymentTaxInfo",
        "schema": "payment-tax-info"
    },
    "ShippingOrder": {
        "className": "ShippingOrder",
        "schema": "shipping-order"
    },
    "PaymentRefundInfo": {
        "className": "PaymentRefundInfo",
        "schema": "payment-refund-info"
    },
    "ReportItemReturn": {
        "className": "ReportItemReturn",
        "schema": "report-item-return"
    },
    "PaymentCardInfo": {
        "className": "PaymentCardInfo",
        "schema": "payment-card-info"
    },
    "PaymentChargeInfo": {
        "className": "PaymentChargeInfo",
        "schema": "payment-charge-info"
    },
    "PaymentOrchestrator": {
        "className": "PaymentOrchestrator",
        "schema": "payment-orchestrator"
    },
    "PaymentPsp": {
        "className": "PaymentPsp",
        "schema": "payment-psp"
    },
    "ReportItemAdSource": {
        "className": "ReportItemAdSource",
        "schema": "report-item-ad-source"
    },
    "ReportItemFunnel": {
        "className": "ReportItemFunnel",
        "schema": "report-item-funnel"
    }
}