import React, { Fragment } from 'react'
import { useListTableRow } from '@newageerp/v3.bundles.app-bundle';

interface Props {
    fieldKey: string,
}

export default function CardData(props: Props) {
    const { element } = useListTableRow();

    return (
        <div className='flex gap-x-2 items-center w-[50px]'>
             {!!element.paymentOrchestrator && <Fragment>
                <img className='max-h-[15px] max-w-[50px]' src={`/payment/icons/${element.paymentOrchestrator.orchestratorName}.svg`} alt={element.paymentOrchestrator.orchestratorName} title={element.paymentOrchestrator.orchestratorName} />
            </Fragment>}
        </div>
    )
}