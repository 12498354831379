import React, { Fragment } from 'react'
import { useTemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { DfValueView, useListTableRow } from '@newageerp/v3.bundles.app-bundle';
import { usePaymentHookNae } from '../../../hooks/usePaymentHookNae';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { useNaeWindow } from '@newageerp/v3.popups.window-provider';
import { Card } from '@newageerp/crm-ui'

interface Props {
    fieldKey: string,
}

export default function RefundAction(props: Props) {
    const row = useListTableRow();
    const element = usePaymentHookNae(row && row.element ? row.element.id : -1);
    const { showEditPopup } = useNaeWindow();

    if (!element) {
        return <Fragment />
    }

    const startRefund = () => {
        showEditPopup({
            id: 'new',
            schema: "payment-refund-request",
            newStateOptions: {
                createOptions: {
                    convert: {
                        schema: "payment",
                        element: { id: element.id },
                    },
                },
            }
        })
    }

    return (
        <div className='space-y-2'>
            {element.refundRequests.length > 0 &&
                <Card.widget.InfoCardSmallWidget isCompact={true}>
                    <p>Refund requests: {element.refundRequests.length}</p>
                    {element.refundRequests.map((rfr) => {
                        return <DfValueView as='string' id={rfr.id} path='payment-refund-request.createdAt' key={`rfr-${rfr.id}`}/>
                    })}

                </Card.widget.InfoCardSmallWidget>
            }
            <MainButton color='pink' onClick={startRefund}>
                Refund
            </MainButton>
        </div>
    )
}
